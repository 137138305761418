import {customClickOutsideCallback} from "@scripts/common";

$(".--open-menu").on("click", function () {
  const getContainer = $(this).closest(".--menu-container");

  $(this).toggleClass("-active");
  getContainer.toggleClass("-active");
  $('#navbar').toggleClass("-active");
});

customClickOutsideCallback(".--menu", function (e) {
  if (!$(".--menu-container").hasClass("-active")) return;

  const isButton = $(e.target).is(".--open-menu");
  const isButtonChild = $(e.target).closest(".--open-menu").length;
  const isMenu = $(e.target).is(".--menu");
  const isMenuChild = $(e.target).closest(".--menu").length;

  const checkButton = isButton || isButtonChild;
  const checkMenu = isMenu || isMenuChild;

  if (!checkButton && !checkMenu) {
    $(".--open-menu").removeClass("-active");
    $(".--menu-container").removeClass("-active");
  }
});

function openModal() {
  $(".modal-background").addClass("-open");
  lockScroll();
}

function closeModal() {
  $(".modal-background").removeClass("-open");
  unlockScroll();
}

$(document).on("click", ".open-modal", openModal);
$(document).on("click", ".close-modal", closeModal);

customClickOutsideCallback(".modal-background .modal-window", function () {
  if ($(".modal-background").hasClass("-open")) {
    closeModal();
  }
});
