// Prevent default function

export function preventDefault(e) {
    e.preventDefault();
}

// Scroll lock

export function lockScroll() {
    const html = document.documentElement;
    const body = document.body;

    const scrollTop = window.scrollY;

    html.classList.add("-scroll-lock");
    body.classList.add("-scroll-lock");

    document.body.scrollTo(0, scrollTop);
    html.setAttribute("data-scroll", scrollTop);

    $(".--modal-scrollable-element").on("touchmove pointermove", preventDefault);
}

export function unlockScroll() {
    const html = document.documentElement;
    const body = document.body;

    const scrollPositionBeforeLock = html.getAttribute("data-scroll");

    html.classList.remove("-scroll-lock");
    body.classList.remove("-scroll-lock");

    window.scrollTo(0, scrollPositionBeforeLock);
    document.body.scrollTo(0, 0);

    $(".--modal-scrollable-element").off("touchmove pointermove", preventDefault);
}


export function checkDeviceSize() {
    window.isPc = window.innerWidth > 1024;
    window.isTablet = window.innerWidth > 759 && window.innerWidth <= 1024;
    window.isMobile = window.innerWidth < 760;
}


// Set CSS variable with window.innerHeight

export function setCssWindowInnerHeight() {
    document.documentElement.style.setProperty(
        "--window-inner-height",
        `${window.innerHeight}px`
    );
}


// Set CSS variable with scrollbar width

export function setScrollbarWidthInCSS() {
    $("body").append(`
		<div id="scrollbar-width-test" style="position: absolute;top: -999px;left: -999px;width: 50px;height: 50px;overflow: scroll;">
			<div style="height: 100px;"></div>
		</div>
	`);

    const scrollbarWidthTestEl = $("#scrollbar-width-test")[0];
    const scrollbarWidth =
        scrollbarWidthTestEl.offsetWidth - scrollbarWidthTestEl.clientWidth;

    document.documentElement.style.setProperty(
        "--scrollbar-width",
        `${scrollbarWidth}px`
    );

    window.scrollbarWidth = scrollbarWidth;

    scrollbarWidthTestEl.remove();
}


// Click outside callback

export function customClickOutsideCallback(selector, callback) {
    $(document).on("mouseup", function (e) {
        const isSelector = $(e.target).is(selector);
        const hasParent = $(e.target).closest(selector).length;

        if (!isSelector && !hasParent && typeof callback === "function") {
            callback(e, selector);
        }
    });
}

/**
 * Get Url Param
 */
export function getUrlParameter(sParam)
{
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++)
    {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam)
        {
            return sParameterName[1];
        }
    }
}

/**
 * Deep merge two or more objects or arrays.
 * @returns {*}          The merged arrays or objects
 * @param {*} objs
 */
export function deepMergeObjects (...objs) {

    /**
     * Get the object type
     * @param  {*}       obj The object
     * @return {String}      The object type
     */
    function getType (obj) {
        return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
    }

    /**
     * Deep merge two objects
     * @return {Object}
     */
    function mergeObj (clone, obj) {
        for (let [key, value] of Object.entries(obj)) {
            let type = getType(value);
            if (clone[key] !== undefined && getType(clone[key]) === type && ['array', 'object'].includes(type)) {
                clone[key] = deepMergeObjects(clone[key], value);
            } else {
                clone[key] = structuredClone(value);
            }
        }
    }

    // Create a clone of the first item in the objs array
    let clone = structuredClone(objs.shift());

    // Loop through each item
    for (let obj of objs) {

        // Get the object type
        let type = getType(obj);

        // If the current item isn't the same type as the clone, replace it
        if (getType(clone) !== type) {
            clone = structuredClone(obj);
            continue;
        }

        // Otherwise, merge
        if (type === 'array') {
            clone = [...clone, ...structuredClone(obj)];
        } else if (type === 'object') {
            mergeObj(clone, obj);
        } else {
            clone = obj;
        }

    }

    return clone;

}

export function getCssVar(name, element = document.body) {
    return getComputedStyle(element).getPropertyValue(name);
}

export function stripUnit(value) {
    return parseInt(value, 10);
}