import Swiper from "swiper";
import {Navigation} from "swiper/modules";

function initDefaultSlider(ths)
{
    const getSlider = $(ths);
    const getPrevSlider = getSlider.find(".--default-prev-arrow");
    const getNextSlider = getSlider.find(".--default-next-arrow");

    const slider = new Swiper(getSlider[0], {
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 16,
        speed: 400,
        touchEventsTarget: "container",
        draggable: false,
        simulateTouch: false,
        loop: false,
        centeredSlides: true,
        modules: [ Navigation ],
        navigation: {
            prevEl: getPrevSlider[0],
            nextEl: getNextSlider[0],
        },

        breakpoints: {
            760: {
                spaceBetween: 20,
                centeredSlides: false,
            },
        },
    });
}

$(document).ready(function () {
    const getSwiper = $(".swiper");

    if (!getSwiper.length) {
        return;
    }

    getSwiper.each(function () {
        if ($(this).is(".--default-slider")) {
            initDefaultSlider(this);
        }
    });
});
