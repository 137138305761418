import {deepMergeObjects, getCssVar, stripUnit} from "@scripts/common.js";
import Swiper from "swiper";
import {Pagination, Autoplay, Navigation} from "swiper/modules";
//import Swiper from "swiper";
//import {Pagination, Navigation, EffectFade, Autoplay} from "swiper/modules";
//import Swiper from 'swiper/bundle';


export default (componentConfig = {}) => ({
    swiperContainer: null,
    captionContainer: null,
    swiper: null,
    config: null,
    init() {
        if(!this.$refs.swiperContainer) {
            return;
        }
        this.swiperContainer = this.$refs.swiperContainer;
        const breakpointNames = ['mobile', 'tablet', 'desktop'];
        let breakpoints = {}
        for(let i in breakpointNames) {
            let key = breakpointNames[i];
            breakpoints[key] = stripUnit(getCssVar(`--breakpoint-${key}`));
        }

        let defaultConfig = {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: false,
            breakpoints: {},
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }

        };

        if(componentConfig.breakpoints !== undefined) {
            for (let key in componentConfig.breakpoints) {
                if (breakpoints[key] !== undefined) {
                    componentConfig.breakpoints[breakpoints[key]] = componentConfig.breakpoints[key];
                    delete componentConfig.breakpoints[key];
                }
            }
        }

        let config = deepMergeObjects(defaultConfig, componentConfig);
        let modules = [];
        //config = {...defaultConfig, ...componentConfig};



        //console.log(breakpoints, componentConfig, config)
        let moduleConfig = {
            autoplay: Autoplay,
            pagination: Pagination,
            navigation: Navigation
        };

        for(let key in moduleConfig) {
            if(componentConfig[key] !== undefined && componentConfig[key] !== false) {
                modules.push(moduleConfig[key]);
                config[key] = {
                    ...(defaultConfig[key] ?? {}),
                    ...(componentConfig[key] === true ? {} : componentConfig[key])
                     }
            } else {
                delete config[key];
            }
        }
        config.modules = modules;

        var handleEvents = [];

        var self = this;
        if(this.$refs.captionContainer) {
            this.captionContainer = this.$refs.captionContainer;
            config.on = {
                ...config.on,
                slideChangeTransitionStart: (swiper) => {
                    //let swiper = this;

                    let activeSlide = $(swiper.slides[swiper.activeIndex]);
                    //console.log(swiper.activeIndex)
                    let caption = activeSlide.data('caption');
                    if(caption) {
                        this.captionContainer.innerHTML = caption;
                    } else {
                        this.captionContainer.innerHTML = '';
                    }
                }
            }
        }

        //console.log(config)
        this.swiper = new Swiper(this.swiperContainer, this.config = config);
    }
});