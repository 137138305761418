const navbar = document.getElementById("navbar");
let sticky = navbar.offsetTop + navbar.clientHeight;
let prevScrollpos = window.scrollY;
let isScrollingUp = false;
let timeout = null;
let timeout2 = null;
let isSticky = false;



function stickyNavbar() {
    const currentScrollPos = window.scrollY;
    const scrollThreshold = 5;

    if (isSticky && prevScrollpos - currentScrollPos > scrollThreshold) {
        // Show navbar
        if (isScrollingUp) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                navbar.classList.remove("-hide");

            }, 100);
            isScrollingUp = false;
        }
    } else if (isSticky && currentScrollPos - prevScrollpos > scrollThreshold) {
        // Hide navbar
        if (!isScrollingUp) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                navbar.classList.add("-hide");
            }, 100);
            isScrollingUp = true;
        }
    }
    prevScrollpos = currentScrollPos;

    if (!isSticky && currentScrollPos > sticky) {
        // Sticky navbar
        navbar.classList.add("-sticky");
        navbar.classList.add("-hide");

        clearTimeout(timeout2);
        timeout2 = setTimeout(() => {
            navbar.classList.add("-transition");
        }, 300);

        isSticky = true;
    } else if (isSticky && currentScrollPos <= 0) {
        // Unsticky navbar
        navbar.classList.remove("-sticky");
        navbar.classList.remove("-hide");
        navbar.classList.remove("-transition");
        isSticky = false;
    }
}

$(document).ready(function() {
    sticky = navbar.offsetTop + navbar.clientHeight
    prevScrollpos = window.scrollY;
    $(window).on("scroll", stickyNavbar)
})
