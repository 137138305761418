import {preventDefault} from '@scripts/common'

$(document).on('click', '.cart__card .remove', function () {
    $(this).closest('.cart__card').remove();
})

$(document).ready(function() {
    const cartItems = parseInt($('.page-nav').data('cartItems'))
    var cartIcon = $('.page-nav .user-menu .user-menu-warenkorb, .mob-buttons .user-menu-warenkorb')
    if(cartItems > 0 && cartIcon) {
        cartIcon.find('.icon-box').append(
            $('<span>', {class: 'basket-number', text: cartItems })
        )
    }
})