$(document).ready(function () {
  if ($('.seminar-card').length) {
    $('.seminar-card').each(function (index) {
      if (index > 5) {
        $(this).css('display', 'none');
      }
    })
  }
})

$(".seminars-block .default-button").on("click", function () {
  $(".seminars-block .list").addClass("-loading");

  setTimeout(() => {
    $(".seminars-block .seminar-card").removeAttr("style");
    $(".seminars-block .list").removeClass("-loading");

    $(this).remove();
  }, 1000);
});
