$(window).on("wishlist:updated", function (e, response) {
    var listIcon = $('.page-nav .user-menu .user-menu-merkliste')

    if(!response.success || !response.data || !listIcon) {
        return;
    }

    listIcon.find('.icon-box').append(
        $('<span>', {class: 'basket-number', text: response.data.count })
    )
});

$(document).ready(function() {
    const listItems = parseInt($('.page-nav').data('wishlistItems'))
    var listIcon = $('.page-nav .user-menu .user-menu-merkliste')
    if(listItems > 0 && listIcon) {
        listIcon.find('.icon-box').append(
            $('<span>', {class: 'basket-number', text: listItems })
        )
    }
})

function wishlistRequest (action, postId, method = 'GET', callback = null) {
    const data = {
        action: action,
        postId: postId,
    }

    //console.log(data)
    jQuery.ajax({
        type: method,
        dataType: "json",
        url: window.app.ajax_url,
        data: data,

        success: function (response) {
            console.log(response)
            if(!response.success) {
                console.error(response.message)
            }
            if (callback) {
                callback(response)
            }
            //console.log(response);
        },
        error: function (response) {
            console.error(response);
        },
        statusCode: {
            404: function () {
                console.error("not found");
            },
            500: function () {
                console.error("invalid request");
            }
        }
    })
}

export var wishlistCounter = (count = undefined) => ({
    count: count,

    ['@wishlist:updated.window'](e, response) {
        console.log('Wishlist updated', e, response)
        if(response && response.success && response.data) {
            this.count = parseInt(response.data.count)
        }
    }
});


export var wishlistButton = (postId, saved = null) => ({
    postId: postId,
    exists: saved,
    requestPending: false,

    init() {
        if(this.exists === null) {
            this.request('fobi/wishlist/exists', 'GET', (response) => {
                this.exists = response.exists
            })
        }
    },


    request(action, callback = null) {
        if(this.requestPending) {
            return;
        }
        this.requestPending = true
        wishlistRequest(action, this.postId, 'POST', callback)
    },

    add() {
        this.exists = true
        this.request('fobi/wishlist/add',  (response) => {
            this.exists = response.success
            this.requestPending = false
            $(window).trigger('wishlist:updated', response)
        })
    },

    remove() {
        this.exists = false
        this.request('fobi/wishlist/remove', (response) => {
            this.exists = !response.success
            this.requestPending = false
            $(window).trigger('wishlist:updated', response)
        })
    },

    toggle() {
        if(this.exists) {
            this.remove()
        } else {
            this.add()
        }
        //this.exists ? this.remove : this.add()
    },

    toggleMode: {
        ['@click']() {
            this.toggle()
        },
    }

});