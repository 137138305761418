import {customClickOutsideCallback} from "@scripts/common";

$(document).on("click", ".--dropdown__value", function () {
    $(this).closest(".--dropdown").toggleClass("-active");
});

$(document).on("click", ".--dropdown__list-item", function () {
    const dropdown = $(this).closest(".--dropdown");

    const valueEl = dropdown.find(".--dropdown__value-text");
    const valueContainer = dropdown.find(".--dropdown__value");

    const text = $(this).text().trim();
    const dataValue = $(this).data("value");

    const value = dataValue ? dataValue : text;

    if (valueEl.length) {
        valueEl.html(text);
    } else {
        valueContainer.html(text);
    }

    dropdown.find("input[type='hidden']").attr("value", value);
    dropdown.find(".--dropdown__list-item").removeClass("-active");
    dropdown.addClass("-selected").removeClass("-active");

    $(this).addClass("-active");
});

$(document).on("click", ".--dropdown__clear", function () {
    const dropdown = $(this).closest(".--dropdown");
    const placeholder = dropdown.data("placeholder");

    const valueEl = dropdown.find(".--dropdown__value-text");
    const valueContainer = dropdown.find(".--dropdown__value");

    if (valueEl.length) {
        valueEl.html(placeholder ? placeholder : "");
    } else {
        valueContainer.html(placeholder ? placeholder : "");
    }

    dropdown.find("input").removeAttr("value");
    dropdown.find(".--dropdown__list-item").removeClass("-active");
    dropdown.removeClass("-selected -active");
});

customClickOutsideCallback(".--dropdown.-active", function (e, selector) {
    $(selector).removeClass("-active");
});

