$(document).on("click", ".--checkbox", function () {
  const input = $(this).find('input[type="checkbox"]');
  const checked = input.is(":checked");

  $(this).toggleClass("-checked");
  input[0].checked = !checked;
});

function customCheckboxReady() {
  $(".--checkbox").each(function () {
    const checked = $(this).find('input[type="checkbox"]').is(":checked");
    const hasCheckedClass = $(this).hasClass("-checked");

    if (checked && !hasCheckedClass) {
      $(this).addClass("-checked");
    } else if (!checked && hasCheckedClass) {
      $(this).removeClass("-checked");
    }
  });
}

$(window).on("load", customCheckboxReady);
