// Filters block - init
const postArchive = $(".filterable-archive")
const productList = postArchive.find('.result-container')

const filterContainer = postArchive.find(".dropdown-filter");
var loadMoreButton = postArchive.find('.show-more');
var resultCount = postArchive.find('.result-count');
const orderByCotainer = postArchive.find(".orderby")

var loading = false;
var loadPending = false;
var page = 1;

function getActiveFilters() {
    var filters = {}
    if(!filterContainer) {
        return;
    }
    console.log(filterContainer);
    filterContainer.find('.filter .option.-selected').each(function () {
        const data = $(this).data();
        if (!data.filterKey || !data.filterValue) {
            return;
        }
        const key = data.filterKey;
        if (!filters[key]) {
            filters[key] = []
        }
        filters[key].push(data.filterValue)
    })

    return filters;
}

function getAction() {
    if(!postArchive) {
        return;
    }

    if(postArchive.hasClass('post-type-archive-product')) {
        return 'get_product_listing'
    } else {
        return 'get_post_listing'
    }


}

function getOrderBy() {
    if(!orderByCotainer) {
        return;
    }

    const active = orderByCotainer.find('li.-selected').first()

    if(!active) {
        return {};
    }
    return {
        order: active.data('sortValue'),
        orderBy: active.data('sortKey'),
    }
}

function syncLoadMore() {
    const el = resultCount.find('span.count')
    const maxPages = el.data('maxPages');


    if(page >= maxPages) {
        loadMoreButton.addClass('disabled')
    } else {
        loadMoreButton.removeClass('disabled')
    }

    $(document).trigger('post-type-archive:load-more')
}

function syncFilters(shouldEmpty = true) {
    loading = true;

    var params = {
        'page': page,
        'action': getAction(),
        ...getActiveFilters(),
        ...getOrderBy(),
    };
    console.log('Sync filters', params)
    if(!params.action) {
        console.error('No action found');
        return;
    }

    if (shouldEmpty) {
        productList.addClass('-loading');
        params['page'] = 1
    }

    jQuery.ajax(
        window.app.ajax_url,
        {
            method: 'GET',
            data: params,
            success: function (data) {
                if (shouldEmpty) {
                    productList.empty()
                }
                productList.append(data)

                resultCount.find('span.count').remove();
                resultCount.prepend(productList.find('span.count').attr('data-page', page));
                productList.find('span.count').remove()

                let count = parseInt(resultCount.find('span.count').data('found-posts'))

                if (count === 0) {
                    postArchive.find('.filter-container').hide();
                } else {
                    postArchive.find('.filter-container').show();
                }

                /*
                let count = parseInt(resultCount.find('span.count').data('found-posts'))

                if (count === 0) {
                    $('.post-type-archive .filter-container').hide();
                } else {
                    $('.post-type-archive .filter-container').show();
                }
                 */

                productList.removeClass('-loading');
                syncLoadMore()
            }
        }
    );
}

$(document).ready(function () {
    filterContainer.on("filter:changed", function (e, el) {
        console.log('Filter changed', el)
        syncFilters(true)
    });

    orderByCotainer.on("changed", function (e, el) {
        syncFilters(true)
    });

    loadMoreButton.on("click", function () {
        page += 1
        syncFilters(false)
    })

    syncLoadMore()
});
