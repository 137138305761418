const DropdownFilterObject = {
  rootElement: null,
  filterItems: null,

  filterContainerSelector: ".filter-container",
  activeContainerSelector: ".active-container",

  triggerEvents: true,

  get filterContainer() {
    return this.rootElement.find(this.filterContainerSelector);
  },
  get activeContainer() {
    return this.rootElement.find(this.activeContainerSelector);
  },

  get categories() {
    return this.filterContainer.find(".filter");
  },

  get openCategories() {
    return this.categories.filter(".-active");
  },

  get filters() {
    return this.filterContainer.find(".filter .option");
  },

  count(selected = false) {
    return selected ? this.selected.length : this.filters.length;
  },

  get selected() {
    return this.filters.filter(".-selected");
  },

  init(rootElement) {
    this.rootElement = rootElement;
    const self = this;
    $(this.categories.get().reverse()).each(function (index) {
      $(this).css("z-index", index);
    });

    this.triggerEvents = false;

    this.selected.each(function () {
      const { key, val } = self.extractFilter($(this));
      self.push(key, val);
    });
    this.triggerEvents = true;

    $(document).on("mouseup", function (e) {
      const openFilterSelector =
        self.filterContainerSelector + " .filter.-active";
      const isSelector = $(e.target).is(openFilterSelector);
      const hasParent = $(e.target).closest(openFilterSelector).length;

      if (!isSelector && !hasParent) {
        self.closeCategories();
      }
    });

    this.categories.find(".label").on("click", function () {
      const category = $(this).closest(".filter");
      category.toggleClass("-active");
    });

    this.filters.on("click", function () {
      let { key, val } = self.extractFilter($(this));
      self.toggle(key, val);
      self.closeCategories();
    });

    this.rootElement.find(".clear-filters").on("click", () => {
      this.clear();
    });

    this.rootElement.find(".collapse-filters").on("click", function () {
      $(this).toggleClass("-open");
      if ($(this).hasClass("-open")) {
        self.filterContainer.show(200);
      } else {
        self.filterContainer.hide(200);
      }
    });

    this.triggerEvent("init");
  },

  closeCategories() {
    this.categories.removeClass("-active");
  },

  getFilterSelector(key, value, selector = "") {
    var result = selector;
    if (key !== undefined && key !== "" && key !== "*") {
      result += '[data-filter-key = "' + key + '"]';
    }

    if (value !== undefined && value !== "" && value !== "*") {
      result += '[data-filter-value = "' + value + '"]';
    }

    return result;
  },

  extractFilter(el) {
    const parent = el.closest("[data-filter-key]");
    const key = parent.data("filterKey");
    const val = parent.data("filterValue");

    if (key === undefined || val === undefined) {
      return;
    }

    return {
      key: key,
      val: val,
    };
  },

  getFilterSelectorFromElement(el) {
    const { key, val } = this.extractFilter(el);
    return this.getFilterSelector(key, val);
  },

  push(key, val) {
    const self = this;
    const selector = this.getFilterSelector(key, val);
    const filters = this.filters.filter(selector);

    filters.each(function () {
      const label = $(this).text();
      const selector = self.getFilterSelectorFromElement($(this));
      if ($(this).hasClass("-disabled")) {
        return;
      }
      $(this).addClass("-selected");
      if (self.activeContainer.find(selector).length !== 0) {
        return;
      }

      const template = self.activeContainer
        .find("template")
        .first()
        .prop("content");

      const elem = $(template)
        .children()
        .first()
        .clone(true)
        .attr("data-filter-key", key)
        .attr("data-filter-value", val);
      elem.children(".label").text(label);
      elem.children(".close").on("click", function () {
        const { key, val } = self.extractFilter($(this));
        self.remove(key, val);
      });
      self.activeContainer.append(elem).addClass("-has-filters");
    });

    this.triggerEvent("changed");
  },

  remove(key, val) {
    const selector = this.getFilterSelector(key, val);
    const filter = this.filters.filter(selector);
    if (filter.hasClass("-disabled")) {
      return;
    }

    filter.removeClass("-selected");
    this.activeContainer.find(selector).remove();

    if (this.selected.length === 0) {
      this.activeContainer.removeClass("-has-filters");
    }
    this.triggerEvent("changed");
  },

  isSelected(key, val) {
    const selector = this.getFilterSelector(key, val);
    return this.filters.filter(selector).filter(".-selected").length > 0;
  },

  toggle(key, val) {
    if (this.isSelected(key, val)) {
      this.remove(key, val);
    } else {
      this.push(key, val);
    }
  },

  clear() {
    this.activeContainer.find(".item").remove();
    this.filters.removeClass("-selected");
    this.activeContainer.removeClass("-has-filters");
    this.triggerEvent("changed");
  },

  triggerEvent(event, target) {
    //console.log(this.rootElement, event, this.triggerEvents);
    if (this.triggerEvents) {
      //console.log("EVENT" , "filter:" + event, this.rootElement, [this])
      this.rootElement.trigger("filter:" + event, [this]);
    }
  },
};

function DropdownFilter(rootElement, name) {
  const obj = Object.create(DropdownFilterObject);
  obj.init(rootElement, name);
  return obj;
}

$(document).ready(function () {
  $(".dropdown-filter").each(function () {
    const filter = DropdownFilter($(this));
  });
});
