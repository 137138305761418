import Swiper from "swiper";
import {Pagination, Autoplay} from "swiper/modules";
//import "@scripts/modules/swiper.js";

$(document).ready(function () {
  const feedbacksBlock = $(".page-builder__section.--testimonials-section");

  if (feedbacksBlock.length) {
    const slider = feedbacksBlock.find(".swiper")[0];
    const pagination = feedbacksBlock.find(".swiper-pagination")[0];

    new Swiper(slider, {
      loop: true,
      effect: "slide",
      autoHeight: true,
      speed: 400,
      modules: [Pagination, Autoplay],
      pagination: {
        el: pagination,
        type: "bullets",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        //pauseOnMouseEnter: true,
      }
    });
  }
})
