import {customClickOutsideCallback} from "@scripts/common";

var page = 1
const resultList = $(".search-results .results-container")
const loadMoreButton = $('.search-results .show-more');

$(".--open-search").on("click", function () {
    const getContainer = $(this).closest(".--search-container");
    const getSearch = getContainer.find(".--search");

    getContainer.addClass("-search-opened");
    getSearch.addClass("-active");
});

customClickOutsideCallback(".--search", function (e) {
    const getContainer = $(".--search-container");

    if (!getContainer.hasClass("-search-opened")) {
        return;
    }

    const isSearch = $(e.target).is(".--search");
    const isSearchChild = $(e.target).closest(".--search").length;

    if (!isSearch || !isSearchChild) {
        const getSearch = getContainer.find(".--search");

        getContainer.removeClass("-search-opened");
        getSearch.removeClass("-active");
    }
});

// Non-submitting search

$('.search-box form').on('submit', function (e) {
    const getInput = $(this).find('input');

    if (getInput.val().length < 1) {
        e.preventDefault();
    }
});

function syncLoadMore()
{

    const searchFor = resultList.data('searchFor');
    const maxPages = resultList.data('maxPages');

    if(page + 1 > maxPages) {
        loadMoreButton.hide();
        return;
    }

    var params = {
        'page': page + 1,
        'search': searchFor,
        'action': 'search_load_more',
    }

    jQuery.ajax(
        window.app.ajax_url,
        {
            method: 'POST',
            data: params,
            success: function (data) {
                resultList.append(data)
                page += 1
                if(page >= maxPages) {
                    loadMoreButton.hide();
                }
            },
        }
    );
}


$(".search-results .show-more").on("click", function () {

    syncLoadMore()
})