import {checkDeviceSize, setCssWindowInnerHeight, setScrollbarWidthInCSS} from "@scripts/common";

window.isPc = window.innerWidth > 1024;
window.isTablet = window.innerWidth > 759 && window.innerWidth <= 1024;
window.isMobile = window.innerWidth < 760;

const isApple = navigator.userAgent.toLowerCase().indexOf("mac") !== -1;
const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") !== -1;

if (isApple) {
    document.body.classList.add("-apple");
}

if (isAndroid) {
    document.body.classList.add("-android");
}

window.addEventListener("DOMContentLoaded", setScrollbarWidthInCSS);
window.addEventListener("resize", setScrollbarWidthInCSS);
window.addEventListener("DOMContentLoaded", setCssWindowInnerHeight);
window.addEventListener("resize", setCssWindowInnerHeight);
window.addEventListener("DOMContentLoaded", checkDeviceSize);
window.addEventListener("resize", checkDeviceSize);


