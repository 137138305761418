import videojs from 'video.js'

$(document).ready(function() {
    window.VIDEOJS_NO_DYNAMIC_STYLE = false;
    $('.wp-block-video:not(.wp-block)  video').each(function() {
         //let player = videojs(this, {});
         $(this).addClass('video-js');
       let player = videojs(this, {
           'fluid': true,
       });
   });
});