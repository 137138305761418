import {getUrlParameter} from '@scripts/common'

// Open speaker card

function toggleSpeakerPanel(el) {
    var box = $(el)
    if(!box.hasClass('speaker-box')) {
        box = box.closest(".speaker-box");
    }

    if(!window.isMobile) {
        const largeCard = box.find(".speaker-card.-large-size");
        const smallCard = box.find(".speaker-card:not(.-large-size)");

        const getHeight = smallCard.outerHeight();
        const getLargeCardHeight = largeCard
            .find(".speaker-large-card-container")
            .outerHeight();

        if (box.hasClass("-opened")) {
            box.removeClass("-opened");
            box.css("height", "");
            largeCard.css("height", "");
        } else {
            let opened = $(".speaker-box.-opened");
            if(opened.length) {
                opened.css("height", "");
                opened.find(".speaker-card.-large-size").css("height", "");
                opened.removeClass("-opened");

                box.delay(400).queue(function() {
                    toggleSpeakerPanel($(this))
                    next()
                })
            } else {
                box.addClass("-opened")
                box.css("height", getHeight + getLargeCardHeight + 34)
                largeCard.css("height", getLargeCardHeight);
            }
        }
    } else {
        const content = box.find(".--open-speaker-content");
        const arrow = box.find(".button-open-speaker");
        if (box.hasClass("-opened")) {
            box.removeClass("-opened");
            arrow.removeClass("-remove");
            content.slideUp(400);
        } else {
            let opened = $(".speaker-box.-opened");

            if(opened.length) {
                opened.removeClass("-opened");
                opened.find('.button-open-speaker').removeClass("-remove");
                opened.find('.--open-speaker-content').slideUp(400);

                box.delay(400).queue(function() {
                    toggleSpeakerPanel($(this))
                    next()
                })
            } else {
                box.addClass("-opened");
                arrow.addClass("-remove");
                content.slideDown(400);
            }
        }
    }
}

$(window).on("load resize", function () {
    const getCard = $(".speaker-box");

    if (!getCard.length) {
        return;
    }

    if (!isMobile) {
        const getContainerOffset = $(".speakers-grid").offset().left;

        getCard.each(function () {
            const getLargeCard = $(this).find(".speaker-card.-large-size");
            const getCardOffset = getLargeCard.offset().left;

            const getOffsetNumber = getContainerOffset - getCardOffset;

            getLargeCard.css("left", `${getOffsetNumber}px`);
        });
    }

});

$(window).on("load", function () {
    let id = getUrlParameter('id')
    if(id) {
        toggleSpeakerPanel(`#speaker-${id}`)
        $('html, body').animate({
            scrollTop: $(`#speaker-${id}-large`).offset().top
        }, 500);
    }
})

$(".speaker-card:not(.-large-size)").on("click", function () {
    return toggleSpeakerPanel($(this))
});

/*
$(".button-open-speaker").on("click", function () {
    const getContainer = $(this).closest(".--open-speaker-container");
    const getContent = getContainer.find(".--open-speaker-content");

    getContainer.addClass("-opened");
    $(this).closest(".arrow").addClass("-remove");
    getContent.slideDown(400);
});


$(
    ".--open-speaker-container .name, .--open-speaker-container .subtitle, .--open-speaker-container .img"
).on("click", function () {
    if (isPc) {
        return;
    }

    const getContainer = $(this).closest(".--open-speaker-container");

    if (!getContainer.hasClass("-opened")) {
        return;
    }

    const getContent = getContainer.find(".--open-speaker-content");
    const getArrow = getContainer.find(".arrow");

    getContent.slideUp(400);
    getArrow.removeClass("-remove");
});
 */