import Swiper from "swiper";
import {Navigation, EffectFade, Pagination} from "swiper/modules";
function initSidebarSwiper()
{
    let sidebar = $(".speaker-panel");
    let photoSlider = sidebar.find(".photo-swiper");
    let textSlider = sidebar.find(".text-swiper");

    if (photoSlider.length && textSlider.length) {
        let navigationContainer = sidebar.find(".swiper-navigation");
        let next = navigationContainer.find(".swiper-button-next")[0];
        let prev = navigationContainer.find(".swiper-button-prev")[0];
        let pagination = sidebar.find(".swiper-pagination");

        const photoSwiper = new Swiper(photoSlider[0], {
            slidesPerView: 1,
            speed: 700,
            allowTouchMove: false,
            effect: "fade",
            loop: true,
            modules: [ Navigation, EffectFade, Pagination ],
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            pagination: {
                el: pagination[0],
                type: "bullets",
                clickable: true,
            },
        });


        const textSwiper = new Swiper(textSlider[0], {
            slidesPerView: 1,
            speed: 700,
            allowTouchMove: false,
            effect: "fade",
            loop: true,
            modules: [ Navigation, EffectFade ],
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
        });
    }
}

$(window).on("load", initSidebarSwiper);
