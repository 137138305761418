$(".change-step").click(function () {
    let activeStep = $(this).closest(".seminar-register__step.-active");
    let nextStep = activeStep.next();

    activeStep.removeClass("-active");
    nextStep.addClass("-active");
});

// Open swiper acorion

$(".open-swiper-accordion").on("click", function () {
    let currentText = $(this).text();
    let newText = $(this).data("change-text");
    $(this).data("change-text", currentText);
    $(this).text(newText);

    const swiperContainer = $(".accordion-swiper");
    const swiperContent = $(".text-swiper");
    const swiperContainerHeight = swiperContent.outerHeight();

    if (swiperContainer.hasClass("-open")) {
        swiperContainer.animate({
            height: `0px`
        }, 400, function () {
            $(this).removeClass("-open");
        });
    } else {
        swiperContainer.animate(
            {
                height: `${swiperContainerHeight}px`
            },
            400,
            function () {
                $(this).addClass("-open");
            }
        );
    }
});
