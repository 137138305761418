import Alpine from 'alpinejs';
import ajax from '@imacrayon/alpine-ajax'
import carousel from "@scripts/components/carousel.js";
import {wishlistButton, wishlistCounter} from "@scripts/components/wishlist.js";
//import ui from "@alpinejs/ui";
//import focus from "@alpinejs/focus";
//import collapse from '@alpinejs/collapse';


window.Alpine = Alpine;
Alpine.plugin(ajax);
//Alpine.plugin(ui);
//Alpine.plugin(focus)
//Alpine.plugin(collapse)

document.addEventListener('alpine:init', () => {
    Alpine.data('swiperCarousel', carousel);

    Alpine.data('wishlistButton', wishlistButton)
    Alpine.data('wishlistCounter', wishlistCounter)

});

Alpine.start();