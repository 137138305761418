//import Swiper from "swiper";
//import {Pagination, Navigation, EffectFade, Autoplay} from "swiper/modules";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

window.Swiper = {
    Swiper: Swiper,
    Pagination: Swiper.Pagination,
    Navigation: Swiper.Navigation,
    EffectFade: Swiper.EffectFade,
    Autoplay: Swiper.Autoplay,
};