import {Loader} from "@googlemaps/js-api-loader"

$(".map-container .full-screen-button").on("click", function () {
  const element = $(".map-container .map")[0];

  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
});

function mapInit(el, mapPosition) {

  //if (google === undefined) {
  //    return;
  //}

  const loader = new Loader({
    apiKey: window.app.gmap_api_key,
    version: "weekly",
    //...additionalOptions,
  });

  loader.load().then(async () => {
    const { Map } = await google.maps.importLibrary("maps");
    var map = new Map(el, {
      center: mapPosition,
      zoom: 16,
      disableDefaultUI: false,
      zoomControl: true,
      //mapId: '7f7d636f57b6bf4a',

      styles: [{
        featureType: "water",
        elementType: "geometry",
        stylers: [{
          color: "#e9e9e9",
        }, {
          lightness: 17,
        }],
      }, {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{
          color: "#f5f5f5",
        }, {
          lightness: 20,
        }],
      }, {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{
          color: "#ffffff",
        }, {
          lightness: 17,
        }],
      }, {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{
          color: "#ffffff",
        }, {
          lightness: 29,
        }, {
          weight: 0.2,
        }],
      }, {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{
          color: "#ffffff",
        }, {
          lightness: 18,
        }],
      }, {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [{
          color: "#ffffff",
        }, {
          lightness: 16,
        }],
      }, {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{
          color: "#f5f5f5",
        }, {
          lightness: 21,
        }],
      }, {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{
          color: "#dedede",
        }, {
          lightness: 21,
        }],
      }, {
        elementType: "labels.text.stroke",
        stylers: [{
          visibility: "on",
        }, {
          color: "#ffffff",
        }, {
          lightness: 16,
        }],
      }, {
        elementType: "labels.text.fill",
        stylers: [{
          saturation: 36,
        }, {
          color: "#333333",
        }, {
          lightness: 40,
        }],
      }, {
        elementType: "labels.icon",
        stylers: [{
          visibility: "off",
        }],
      }, {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{
          color: "#f2f2f2",
        }, {
          lightness: 19,
        }],
      }, {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [{
          color: "#fefefe",
        }, {
          lightness: 20,
        }],
      }, {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{
          color: "#fefefe",
        }, {
          lightness: 17,
        }, {
          weight: 1.2,
        }],
      }],
    });

    const { Marker } = await google.maps.importLibrary("marker");
    const markerImg = document.createElement("img");
    markerImg.src = window.app.icons.map_point;
    const marker =  new Marker({
      position: mapPosition,
      map: map,
      icon: window.app.icons.map_point,
    });
  });


  /*
  new google.maps.Marker({
    position: mapPosition,
    map,
    icon: window.app.icons.map_point,
  });
   */
}


$(document).ready(function () {
  $('.map-container .map').each(function () {
    const el = $(this)

    const mapPosition = {
      lat: parseFloat(el.data('latitude')),
      lng: parseFloat(el.data('longitude')),
    };

    if (mapPosition.lat === undefined || mapPosition.lng === undefined || mapPosition.lat === 0 || mapPosition.lng === 0) {
      el.remove()
      return;
    }
    mapInit(this, mapPosition)
  })
})

