function formTabsReady() {
  let container = $('.radio-tabs-block');

  if (container.length) {
    container.each(function () {
      let menu = $(this).find('.radio-button-box');
      let checkedInput = menu.find('.radio-item.-checked');

      if (checkedInput.length) {
        let inputIndex = checkedInput.index();

        $(this).find('.displayed-input .tabs-item').eq(inputIndex).addClass('-active');
      }

    })
  }
}

function formTabsChanged(el) {
  let container = el.closest('.radio-tabs-block')
  let inputIndex = el.index();

  container.find('.displayed-input .tabs-item.-active').removeClass('-active');

  container.find('.displayed-input .tabs-item').eq(inputIndex).addClass('-active');
}

$(window).on('load', formTabsReady);
