import {customClickOutsideCallback} from "@scripts/common.js";

const TextDropdownObject = {
    rootElement: null,
    init: function (rootElement) {
        this.rootElement = rootElement;
        this.rootElement.find("span").on("click", function () {
            $(this).closest(".text-dropdown").toggleClass("-active");
        });

        this.rootElement.find("li").on("click", function () {
            const text = $(this).text().trim();

            if($(this).hasClass("-selected")) {
                return;
            }

            rootElement.find("li").removeClass("-selected");
            $(this).addClass("-selected");


            rootElement.removeClass("-active")
                .find(".label")
                .html(text);


            rootElement.trigger("changed", [text])
        });

        $(document).on("click", function (e) {
            const isSame = $(e.target).is(rootElement);
            const isParent = $(e.target).closest(".text-dropdown").length;
            if(!isSame && !isParent) {
                rootElement.removeClass("-active");
            }
        })

    }
}

function TextDropdown(rootElement) {
    const obj = Object.create(TextDropdownObject);
    obj.init(rootElement);
    return obj;
}

$(document).ready(function () {
    $(".text-dropdown").each(function () {
        const filter = TextDropdown($(this));
    });
});

$(".text-dropdown ul li").on("click", function () {

});


