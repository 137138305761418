function syncRadioGroup(group) {
    group.find('.--radio').each(function () {
        syncRadio($(this))
    })
}

function syncRadio(radio) {

    if(!radio.hasClass('--radio')) {
        return;
    }

    const checked = radio.find('input[type="radio"]').is(":checked");
    const hasCheckedClass = radio.hasClass("-checked");

    if (checked && !hasCheckedClass) {
        radio.addClass("-checked");
    }
    else if (!checked && hasCheckedClass) {
        radio.removeClass("-checked");
    }
}


window.initRadio = function(group) {


    if(group.attr('data-initialized') !== undefined) {
        return;
    }

    syncRadioGroup(group)

    group.find('.--radio').on('click', function() {
        group.find("input[type='radio']:checked").prop('checked', false)
        $(this).find("input[type='radio']").prop('checked', true)
        group.find("input[type='radio']").trigger('change')
    }).find("input[type='radio']").on('change', function() {
        syncRadio($(this).closest('.--radio'))
    })
    group.attr('data-initialized', 'yes')
}

$(document).ready(function() {
    $(".radio-button-box").each(function () {
        window.initRadio($(this))
    })
})
